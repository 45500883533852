import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/configs/app.config';
import { AppConfig } from '@core/models/config';
import {
  CountryData,
  LoginData,
  SignupData,
  SignupMarketplaceData,
  UserDataResponse,
} from '@core/models/auth';
import { Observable, Subject, map, of, retry, take, tap } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { CountryDataResponse } from '@core/models/api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userAuthorized: boolean | null = null;
  private _userAuthorized$ = new Subject<boolean>();

  private _countriesListCache: CountryData[] = [];
  private _countriesListCacheLastUpdate = new Date(0);
  private readonly CACHE_REFRESH_TIME = 5 /*minutes*/ * 60 * 1000;
  private readonly API = {
    user: '/meta/api/v1/user',
    login: '/meta/api/v1/user/auth/login',
    logout: '/meta/api/v1/user/auth/logout',
    signup: '/meta/api/v1/user/auth/register',
    countryList: '/meta/api/v1/user/auth/register/country-list',
    refreshSession: '/meta/api/v1/session/touch',
  };

  constructor(
    private _http: HttpClient,
    private _analytics: AnalyticsService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this._initRefreshAuthTimer();
  }

  public login(data: LoginData): Observable<unknown> {
    const body = new URLSearchParams();
    body.set('uid', data.login);
    body.set('pwd', data.password);
    return this._http
      .post<unknown>(this.API.login, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .pipe(retry(1));
  }

  public logout() {
    return this._http.post<unknown>(this.API.logout, {});
  }

  public signup(data: SignupData): Observable<unknown> {
    const marketplaceData: SignupMarketplaceData = {
      login: data.login,
      email: data.email,
      country: data.country,
    };
    return this._http.post<unknown>(this.API.signup, marketplaceData);
  }

  public getCountries(): Observable<CountryData[]> {
    const loadCountries$ = this._http
      .get<CountryDataResponse>(this.API.countryList)
      .pipe(map((response) => response.data));

    const msFromLastLoad =
      Date.now() - this._countriesListCacheLastUpdate.getTime();
    if (msFromLastLoad < this.CACHE_REFRESH_TIME)
      return of(this._countriesListCache);
    this._countriesListCacheLastUpdate = new Date();
    return loadCountries$.pipe(
      tap((countries) => (this._countriesListCache = countries))
    );
  }

  public getUser(): Observable<UserDataResponse> {
    const source = this._http.get<UserDataResponse>(this.API.user);
    return source.pipe(
      tap({
        next: (resp) => {
          if (resp.user === null) {
            this._setUserAuthStatus(false);
            return;
          }
          const license = resp.user.license;
          if (license) this._analytics.setUserId(license.slice(0, 14));
          this._setUserAuthStatus(true);
        },
        error: () => this._setUserAuthStatus(false),
      })
    );
  }

  public isUserAuthorized(): Observable<boolean> {
    if (this._userAuthorized !== null) return of(this._userAuthorized);
    return this._userAuthorized$.pipe(take(1));
  }

  private _setUserAuthStatus(isAuthorized: boolean) {
    this._userAuthorized = isAuthorized;
    this._userAuthorized$.next(isAuthorized);
  }

  private _initRefreshAuthTimer() {
    this.isUserAuthorized().subscribe((isAuthorized) => {
      if (!isAuthorized) return;
      setInterval(() => {
        this._http.post(this.API.refreshSession, {}).subscribe();
      }, this.appConfig.auth.refreshTime);
    });
  }
}
